<template>
  <v-dialog
    v-model="visible"
    :width="width || 800"
  >
    <v-card
      flat
      tile
      color="white"
      :height="height"
    >
      <!-- <v-divider /> -->
      <v-img
        v-if="article.cover"
        :src="article.cover"
        :height="coverHt"
        cover
      />
      <v-card-title class="text-h5">
        {{ article.title }}
      </v-card-title>
      <div class="co-flex-row co-items-center pa-6">
        <v-avatar
          size="64"
        >
          <v-img
            :src="userInfo.avatar"
          />
        </v-avatar>
        <span class="co-text-md ml-4">{{ userInfo.username }}</span>

        <span
          class="co-text-sm co-text-gray-500 ml-6"
        >
          {{ article.createTime || nowTime }}
        </span>
      </div>
      <v-divider class="mb-4" />
      <div
        class="ql-editor white px-6"
        style="height: auto; overflow: hidden;"
        v-html="article.content"
      />
    </v-card>
  </v-dialog>
</template>

<script>
  import api from '@/api/co.api'
  import user from '@/api/co.user'
  import dayjs from 'dayjs'

  export default {
    props: {
      width: {
        type: [Number, String],
        default: 740
      },
      height: {
        type: [Number, String],
        default: 800
      },
    },
    data () {
      return {
        visible: false,
        coverHt: 400,
        article: {},
        userInfo: {},
        nowTime: ''
      }
    },
    created () {
      this.nowTime = dayjs().format('YYYY-MM-DD HH:mm')
      this.userInfo = user.info.get()
    },
    methods: {
      show (item) {
        this.article = api.comm.deepClone(item)
        if (this.article.content) {
          this.article.content = this.article.content.replace(/<img/gi, '<img style="max-width:100%;height:auto;"')
        }
        this.visible = true
      },
      hide () {
        this.visible = false
      }
    }
  }
</script>
